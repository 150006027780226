import Vue from 'vue';
import Sender from '@razlet/sdk/lib/senders/base';

function sendError(error) {
  if (error.status === 301 || error.status === 404) throw error;
  throw new Error(error.data?.error?.message || error.data?.message || error.message || error);
}

const state = () => ({
  host: null,
});

const getters = {};

const mutations = {
  setValue(state, data) {
    Vue.set(state, data.key, data.value);
  },
  clearValue(state, data) {
    Vue.set(state, data.key, null);
  },
};

const actions = {
  async initSuluSender ({ commit, state }, {req, isDev}) {
    if (!state.host) {
      const host = isDev || process.env.suluURL ? process.env.suluURL : `https://${req ? req.headers.host : window.location.host}`;
      commit('setValue', {key: 'host', value: host});
    }
  },
  // eslint-disable-next-line no-unused-vars
  async getApiData({commit, state}, {path, json = true}) {
    if (typeof window !== 'undefined' && window.SULU_DATA) {
      return window.SULU_DATA;
    }
    const sender = new Sender({baseUrl: `${state.host}/${Vue.prototype.$locale}`});
    const url = `/api/${path}${json ? '.json' : ''}`;
    const response = await sender.get(url)
      .then((res) => {
        if (res && res.status === 200) {
          return res.data;
        }
        return {};
      })
      .catch(e => {
        sendError(e);
      });
    return response;
  },
  // eslint-disable-next-line no-unused-vars
  async getData({commit, state}, {path, json = true}) {
    if (typeof window !== 'undefined' && window.SULU_DATA) {
      return window.SULU_DATA;
    }
    const sender = new Sender({baseUrl: `${state.host}/${Vue.prototype.$locale}`});
    const url = `${path}${json ? '.json' : ''}`;
    const response = await sender.get(url)
      .then((res) => {
        if (res) {
          if (res.status === 200 && res.data.redirect) {
            // eslint-disable-next-line no-throw-literal
            throw {
              status: 301,
              url: res.data.url.replace('.json', ''),
            };
          }
          if (res.status !== 200) {
            // eslint-disable-next-line no-throw-literal
            throw {
              status: res.status,
              message: res.statusText,
            };
          }
          return res.data;
        }
        return {};
      })
      .catch(e => {
        sendError(e);
      });
    return response;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};