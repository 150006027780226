
import snowBg from '~/assets/img/snow-bg.png';
import elsomLogo from '~/static/img/elsom.webp';
import qrIOS from '~/static/qr/qr-ios.webp';
import qrAndroid from '~/static/qr/qr-android.webp';

export default {
  name: 'footer-block',
  components: {
    'icon-apple': () => import('~/components/icons/icon-apple'),
    'icon-playmarket': () => import('~/components/icons/icon-playmarket'),
  },
  data() {
    return {
      elsomLogo,
      snowBg,
      year: (new Date()).getFullYear(),
      appStoreLink: 'https://apple.co/47n9RaF',
      playmarketLink: 'https://play.google.com/store/apps/details?id=com.razlet.kg',
      deviceInfo: null,
    };
  },
  computed: {
    qrs () {
      const iosQr = {
        image: qrIOS,
        title: 'App Store',
        icon: 'icon-apple',
        link: this.appStoreLink,
      };

      const androidQr = {
        image: qrAndroid,
        title: 'Google Play',
        icon: 'icon-playmarket',
        link: this.playmarketLink,
      };

      if (this.isMobile) {
        return this.isIOS ? [iosQr] : [androidQr];
      }

      return [iosQr, androidQr];
    },
    isIOS () {
      if (!this.deviceInfo) return false;
      return this.deviceInfo.operatingSystem.includes('ios');
    },
  },
  mounted () {
    this.setDeviceInfo();
  },
  methods: {
    async setDeviceInfo () {
      this.deviceInfo = await this.$deviceInfo();
    },
    openApp (link) {
      window.open(link, '_system');
    },
  },
};
